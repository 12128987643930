import { useState } from "react";
import { logInWithEmailAndPassword } from "../firebase"
import { Button, Container, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword((show) => !show)

    return (
        <Container>
            <form onSubmit={(e) => { e.preventDefault(); logInWithEmailAndPassword(email, password); }}>
                <Stack spacing={2}>
                    <TextField
                        label="Email address"
                        type="email"
                        id="username"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type={(showPassword && "text") || "password"}
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <Button variant="contained" type="submit" sx={{ alignSelf: 'flex-end' }}>
                        Login
                    </Button>
                </Stack>
            </form>
        </Container>
    );
};

export default Login;