import { User } from "firebase/auth";
import { logout } from "../firebase";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";

function TopBar({ user }: { user: User | null | undefined }) {
    return (
        <div className="topBar">
            <AppBar>
                <Toolbar>
                    {!user &&
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Login
                        </Typography>
                    }
                    {user &&
                        <>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Welcome!
                            </Typography>
                            <Typography sx={{ mr: 2 }} variant="subtitle1">
                                {user.email}
                            </Typography>
                            <Button color="inherit" onClick={() => logout()}>
                                Logout
                            </Button>
                        </>
                    }
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    );
}

export default TopBar;