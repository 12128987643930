import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";

import { getDatabase } from "firebase/database"

const firebaseConfig = {
    apiKey: "AIzaSyBAtN9m8vPmPCyefyx0FseQUEIJP72XG0s",
    authDomain: "esp-climate-38212.firebaseapp.com",
    databaseURL: "https://esp-climate-38212-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "esp-climate-38212",
    storageBucket: "esp-climate-38212.appspot.com",
    messagingSenderId: "890533766470",
    appId: "1:890533766470:web:20f36dc2059889507a3147"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);

export const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
        console.error(err);
        alert(err.message);
    }
};

export const logout = () => {
    signOut(auth);
};