import { useEffect, useMemo } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

import { auth } from "./firebase"
import { useAuthState } from "react-firebase-hooks/auth";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import TopBar from "./components/TopBar";
import { User } from "firebase/auth";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";

const AppLayout = ({ user }: { user: User | null | undefined }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() => createTheme({
    palette: { mode: prefersDarkMode ? 'dark' : 'light' }
  }), [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar user={user} />
      <Box sx={{ mt: 4, mx: 2 }}>
        <Outlet />
      </Box>
    </ThemeProvider>
  )
};

function App() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/")
    else navigate("/dashboard");
  }, [user, loading, navigate]);


  if (loading)
    return <div>Loading...</div>;

  if (error)
    alert(error.message);

  return (
    <Routes>
      <Route element={<AppLayout user={user} />} >
        <Route index element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default App;
