import { db } from "../firebase";
import { useList } from "react-firebase-hooks/database";
import { DataSnapshot, ref } from "firebase/database";
import { Fragment, useMemo, useState } from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import {
    Divider,
    NativeSelect,
    Paper,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";

type Snapshot = {
    temperature: number;
    humidity: number;
    pressure: number;
};

type Series = {
    name: string;
    unit: string;
    data: SeriesData[];
};

type SeriesData = {
    date: number;
    data: number;
};

function Dashboard() {
    const [snapshots, loading, error] = useList(ref(db, "esp01"));
    const [timeFrame, setTimeFrame] = useState(31536000);
    const useTimeFrameSelect = useMediaQuery("(min-width:720px)");

    // Generate data for chart
    const snapShotData: Series[] = useMemo(() => {
        console.log("Generating data...");

        const temperatures: SeriesData[] = [];
        const humidities: SeriesData[] = [];
        const pressures: SeriesData[] = [];

        snapshots?.forEach((snapshot) => {
            let date = parseInt(snapshot.key!!) * 1000;
            let value = snapshot.val() as Snapshot;

            temperatures.push({ date, data: value.temperature });
            humidities.push({ date, data: value.humidity });
            pressures.push({ date, data: value.pressure });
        });

        return [
            {
                name: "Temperature",
                unit: "°C",
                data: temperatures,
            },
            {
                name: "Humidity",
                unit: "%",
                data: humidities,
            },
            {
                name: "Pressure",
                unit: "hPa",
                data: pressures,
            },
        ];
    }, [snapshots]);

    // Filter data by time frame
    const chartData: Series[] = useMemo(() => {
        const now = Date.now();
        const cutoffDate = new Date(now - timeFrame * 1000);

        console.log("Filter by date:", cutoffDate.toLocaleString("de-DE"));

        return snapShotData.map((series) => {
            return {
                ...series,
                data: series.data.filter((data) => data.date >= cutoffDate.getTime()),
            };
        });
    }, [snapShotData, timeFrame]);

    if (loading) return <div>Loading data...</div>;

    if (error) alert(error.message);

    const formatXAxis = (tickItem: number) => {
        let date = new Date(tickItem);
        if (timeFrame <= 86400) return date.toLocaleTimeString();
        else return date.toLocaleDateString();
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h2">
                Overview
            </Typography>

            <OverviewCards data={snapshots?.[snapshots?.length - 1]} />

            <Typography variant="h2">
                History
            </Typography>

            {useTimeFrameSelect ? (
                <ToggleButtonGroup
                    value={timeFrame}
                    exclusive
                    color="primary"
                    onChange={(_e, value) => value && setTimeFrame(value)}
                    sx={{ alignSelf: "end" }}
                >
                    <ToggleButton value={300}>5 min</ToggleButton>
                    <ToggleButton value={1800}>30 min</ToggleButton>
                    <ToggleButton value={3600}>1 hour</ToggleButton>
                    <ToggleButton value={21600}>6 hours</ToggleButton>
                    <ToggleButton value={43200}>12 hours</ToggleButton>
                    <ToggleButton value={86400}>1 day</ToggleButton>
                    <ToggleButton value={604800}>1 week</ToggleButton>
                    <ToggleButton value={2592000}>1 month</ToggleButton>
                    <ToggleButton value={31536000}>1 year</ToggleButton>
                </ToggleButtonGroup>
            ) : (
                <NativeSelect
                    defaultValue={timeFrame}
                    onChange={(e) => setTimeFrame(parseInt(e.target.value))}
                    sx={{ alignSelf: "end" }}
                >
                    <option value={300}>5 min</option>
                    <option value={1800}>30 min</option>
                    <option value={3600}>1 hour</option>
                    <option value={21600}>6 hours</option>
                    <option value={43200}>12 hours</option>
                    <option value={86400}>1 day</option>
                    <option value={604800}>1 week</option>
                    <option value={2592000}>1 month</option>
                    <option value={31536000}>1 year</option>
                </NativeSelect>
            )}

            {chartData.map((series) => {
                return (
                    <Paper key={series.name} sx={{ p: 2 }}>
                        <Stack spacing={2}>
                            <Typography variant="h4">{series.name}</Typography>
                            <Stack direction={"row"} spacing={2}>
                                <Typography>Samples: {chartData[0].data.length}</Typography>
                                <Divider orientation="vertical" flexItem />
                                <Typography>
                                    Average:&nbsp;
                                    {(series.data.reduce((l, r) => l + r.data, 0) / series.data.length)?.toFixed(2)}
                                    &nbsp;
                                    {series.unit}
                                </Typography>
                            </Stack>
                            <ResponsiveContainer width="98%" height={300}>
                                <LineChart data={series.data}>
                                    <Line type="monotone" dataKey="data" stroke="#8884d8" dot={false} />
                                    <XAxis
                                        dataKey="date"
                                        scale="time"
                                        type="number"
                                        domain={["dataMin", "dataMax"]}
                                        allowDataOverflow={true}
                                        tickFormatter={formatXAxis}
                                    />
                                    <YAxis unit={series.unit} tickFormatter={(value) => Math.round(value).toString()} />
                                    <Tooltip formatter={(value) => [value + series.unit, series.name]} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Stack>
                    </Paper>
                );
            })}
        </Stack>
    );
}

export default Dashboard;

function OverviewCards({ data }: { data: DataSnapshot | undefined | null }) {
    const val = data?.val();
    const date = new Date(parseInt(data?.key?.toString() ?? "0") * 1000);

    return (
        <Fragment>
            <Stack direction="row" gap={2}>
                <OverviewCard title="Temperature" value={val?.temperature} unit="°C" />
                <OverviewCard title="Humidity" value={val?.humidity} unit="%" />
                <OverviewCard title="Pressure" value={val?.pressure} unit="hPa" />
            </Stack>
            <Typography variant="subtitle1">
                Last snapshot: {date.toLocaleString()}
            </Typography>
        </Fragment>
    );
}

function OverviewCard({ title, value, unit }: { title: string; value: number | undefined; unit: string }) {
    return (
        <Paper sx={{ p: 2, flexGrow: 1 }}>
            <Stack spacing={2}>
                <Typography variant="h4">{title}</Typography>
                <Divider />
                <Typography variant="h3">
                    {value} {unit}
                </Typography>
            </Stack>
        </Paper>
    );
}